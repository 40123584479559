import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TeamAuthService } from '../auth/auth.service';

@Injectable()
export class TeamHeaderService{
  items: MenuItem[];
  enditems: MenuItem[];
  fullitems: MenuItem[];
  loginitems: MenuItem[];

	constructor(
    private authService: TeamAuthService
    ) {}

  onLogout() {
    this.authService.logOut();
  }

	refreshMenu() {
    this.loginitems = [
      {
        label: 'Log In',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.items = [
      {
        label: 'Overview',
        visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant') || this.authService.isAuthorised('lab'),
        items: [
          {
            label: 'Contacts',
            visible: this.authService.isAuthorised('general'),
            routerLink: '/team/table/contacts',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Companies',
            visible: this.authService.isAuthorised('general'),
            routerLink: '/team/table/companies',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
              separator:true,
              visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance')
          },
          {
            label: 'Deliveries',
            visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance'),
            routerLink: '/team/table/deliveries',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Depot Deliveries',
            visible: this.authService.isAuthenticated('mod-depot') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance')),
            routerLink: '/team/table/depotdeliveries',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Samples',
            visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance'),
            routerLink: '/team/table/samples',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Resamples',
            visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance'),
            routerLink: '/team/table/resamples',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Remittances',
            visible: this.authService.isAuthorised('finance') || this.authService.isAuthorised('remittance') || this.authService.isAuthorised('accountant'),
            routerLink: '/team/table/remittances',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Payments',
            visible: this.authService.isAuthorised('finance') || this.authService.isAuthorised('accountant'),
            routerLink: '/team/table/payments',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: (this.authService.isAuthenticated('mod-schedule') && this.authService.isAuthorised('booking')) || (this.authService.isAuthenticated('mod-calc') && this.authService.isAuthorised('general'))
          },
          {
            label: 'Bookings',
            visible: this.authService.isAuthenticated('mod-schedule') && this.authService.isAuthorised('booking'),
            routerLink: '/team/table/bookings',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Calculator',
            visible: this.authService.isAuthenticated('mod-calc') && this.authService.isAuthorised('general'),
            routerLink: '/team/calculator',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            separator: true,
            visible: this.authService.isAuthenticated('mod-orders') && this.authService.isAuthorised('buying')
          },
          {
            label: 'Orders',
            visible: this.authService.isAuthenticated('mod-orders') && this.authService.isAuthorised('buying'),
            routerLink: '/team/table/orders',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('general') || this.authService.isAuthorised('factory') || this.authService.isAuthorised('manageinventory'))
          },
          {
            label: 'Inventory Produced',
            visible: this.authService.isAuthenticated('mod-inventory') && this.authService.isAuthorised('general'),
            routerLink: '/team/overview/inventory',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Assigned Inventory',
            visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('manageinventory'),
            routerLink: '/team/table/assignedinventory',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance'))
          },
          {
            label: 'Batches',
            visible: this.authService.isAuthorised('factory') || this.authService.isAuthorised('finance'),
            routerLink: '/team/table/batches',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Silos',
            visible: this.authService.isAuthenticated('mod-silos') && this.authService.isAuthorised('factory'),
            routerLink: '/team/overview/silos',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Processing',
            visible: this.authService.isAuthenticated('mod-processing') && this.authService.isAuthorised('factory'),
            routerLink: '/team/overview/processing',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-currency') && this.authService.isAuthorised('currency') 
          },
          {
            label: 'Currency',
            visible: this.authService.isAuthenticated('mod-currency') && this.authService.isAuthorised('currency'),
            routerLink: '/team/overview/currency',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant') || this.authService.isAuthorised('lab'),
            styleClass: 'lastseparator' 
          }
        ]
      },
      {
        label: 'Logging',
        visible: this.authService.isAuthorised('general'),
        items: [
          {
            label: 'Contact',
            visible: this.authService.isAuthorised('general'),
            routerLink: '/team/logging/contact',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Company',
            visible: this.authService.isAuthorised('general'),
            routerLink: '/team/logging/company',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-schedule') && this.authService.isAuthorised('booking')
          },
          {
            label: 'Booking',
            visible: this.authService.isAuthenticated('mod-schedule') && this.authService.isAuthorised('booking'),
            routerLink: '/team/logging/booking',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-messages') && this.authService.isAuthorised('messages')
          },
          {
            label: 'Message',
            visible: this.authService.isAuthenticated('mod-messages') && this.authService.isAuthorised('messages'),
            routerLink: '/team/messages/add',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Message Group',
            visible: this.authService.isAuthenticated('mod-messages') && this.authService.isAuthorised('messages'),
            routerLink: '/team/messages/groups',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthorised('general')
          },
          {
            label: 'Silo Selector',
            visible: this.authService.isAuthenticated('mod-siloselect'),
            routerLink: '/team/logging/silo-selector',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Delivery',
            visible: !this.authService.isAuthorised('siloselectorforced'),
            routerLink: '/team/logging/delivery',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Sample',
            routerLink: '/team/logging/sample',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Payment',
            visible: this.authService.isAuthorised('finance'),
            routerLink: '/team/logging/payment',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-processing') && this.authService.isAuthorised('factory')
          },
          {
            label: 'Processing',
            visible: this.authService.isAuthenticated('mod-processing') && this.authService.isAuthorised('factory'),
            routerLink: '/team/logging/processing',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-orders') && this.authService.isAuthorised('buyingedit')
          },
          {
            label: 'Order',
            visible: this.authService.isAuthenticated('mod-orders') && this.authService.isAuthorised('buyingedit'),
            routerLink: '/team/logging/order',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-currency') && this.authService.isAuthorised('currency')
          },
          {
            label: 'Currency',
            visible: this.authService.isAuthenticated('mod-currency') && this.authService.isAuthorised('currency'),
            routerLink: '/team/logging/currency',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant'),
            styleClass: 'lastseparator' 
          }
        ],
      },
      {
        label: 'Depot',
        visible: this.authService.isAuthenticated('mod-depot') && this.authService.isAuthorised('depot'),
        items: [
          {
            label: 'Log Depot Delivery',
            routerLink: '/team/depot/log-delivery',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Depot Deliveries',
            routerLink: '/team/table/depotdeliveries',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant'),
            styleClass: 'lastseparator' 
          }
        ]
      },
      {
        label: 'Pallets',
        visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('lab')),
        items: [
          {
            label: 'Pallet Sample',
            routerLink: '/team/lab/pallet-sample',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('lab'))
          },
          {
            label: 'Pallet: Samples',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('lab')),
            routerLink: '/team/table/palletsamples',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Pallet: Review',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory')),
            routerLink: '/team/table/palletreview',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Pallet: To Do',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory')),
            routerLink: '/team/table/palletactions',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Recover Pallet',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('lab')),
            routerLink: '/team/pallets/recoverpallet',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('lab'))
          },
          {
            label: 'Picking List',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') ),
            routerLink: '/team/table/pickinglist',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Picked List',
            visible: this.authService.isAuthenticated('mod-inventory') && (this.authService.isAuthorised('factory') ),
            routerLink: '/team/table/pickedlist',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            styleClass: 'lastseparator' 
          }
        ]
      },
      {
        label: 'Traceability',
        visible: this.authService.isAuthenticated('mod-trace') && this.authService.isAuthorised('factory'),
        items: [
          {
            label: 'Trace from Farm',
            routerLink: '/team/traceability/tracefarm',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Trace from Stock',
            routerLink: '/team/traceability/tracestock',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true
          },
          {
            label: 'Packing List',
            routerLink: '/team/traceability/packinglist',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Stock Recall',
            visible: this.authService.isAuthorised('development'),
            routerLink: '/team/traceability/stockrecall',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant'),
            styleClass: 'lastseparator' 
          }
        ]
      },
      {
        label: 'QR Scanner',
        visible: this.authService.isAuthenticated('mod-qr') && (this.authService.isAuthorised('factory') || this.authService.isAuthorised('manageinventory')),
        items: [
          {
            label: 'Inspect',
            routerLink: '/team/qr/inspect',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Manage Inventory',
            routerLink: '/team/qr/inventory',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Dispatch',
            routerLink: '/team/qr/dispatch',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant'),
            styleClass: 'lastseparator' 
          }
        ]
      },
    ];
    this.enditems = [
      {
        label: '',
        icon: 'pi pi-cog',
        visible: this.authService.isAuthorised('admin') || this.authService.isAuthorised('financeadmin'),
        items: [
          {
            label: 'General Settings',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/general/general',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Seasonal Settings',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/general/seasonal',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Bank Accounts',
            visible: this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/general/banking',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthorised('superadmin') || this.authService.isAuthorised('admin')
          },
          {
            label: 'Cultivars',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/general/cultivars',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Email Settings',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/general/email',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Storage',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/general/storage',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthorised('superadmin')
          },
          {
            label: 'Price Models',
            visible: this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/general/pricemodel',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Remittance Rebuilder',
            visible: this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/remittance-rebuilder',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthenticated('mod-clients') && this.authService.isAuthorised('admin')
          },
          {
            label: 'Invite',
            visible: this.authService.isAuthenticated('mod-clients') && this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/clients/new',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Active Accounts',
            visible: this.authService.isAuthenticated('mod-clients') && this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/clients/active',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Client Activity',
            visible: this.authService.isAuthenticated('mod-clients') && this.authService.isAuthorised('admin'),
            routerLink: '/team/settings/clients/activity',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
             separator: true,
             visible: this.authService.isAuthorised('superadmin')
          },
          {
            label: 'New User',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/team/new',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Current Users',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/team/active',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'User Activity',
            visible: this.authService.isAuthorised('superadmin'),
            routerLink: '/team/settings/team/activity',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            visible: this.authService.isAuthorised('general') || this.authService.isAuthorised('accountant'),
            styleClass: 'lastseparator' 
          }
        ]
      },
      
      {
        label: 'Test',
        visible: this.authService.isAuthorised('development'),
        routerLink: '/team/test',
        routerLinkActiveOptions: {
           exact: true
        },
      },
      
      {
        label: 'Log Out',
        styleClass: 'logoutlabel',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.fullitems = [...this.items,...this.enditems];
    return this.fullitems;
  }

}