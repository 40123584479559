<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.aMS.editmode">Add Message Group</h2>
				<h2 *ngIf="this.aMS.editmode">Edit Message Group</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.aMS.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Message Group</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.aMS.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Message Group</button>
			</div>
		</div>
		<br>
		<div class="col col-xl-97">	 	
			<div class="form-container">
			  
			  <mat-form-field *ngIf="this.aMS.editmode">
    			<mat-label>Group Name</mat-label>
			    <mat-select placeholder="Group Name" [(ngModel)]="this.aMS.groupname" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let group of this.dDDS.dropdown.msggroupList" #prod (click)="this.loadForEdit()" value="{{ group.groupname }}">{{ group.groupname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <form [formGroup]="form" *ngIf="!this.aMS.editmode">
				  <mat-form-field>
	    			<mat-label>Group Name</mat-label>
				    <input matInput placeholder="Group Name" maxlength="45" [(ngModel)]="this.aMS.groupname" formControlName="groupname">
			      <mat-error *ngIf="form.get('groupname').errors?.forbiddenString">This group name already exists</mat-error>
			      <mat-error *ngIf="form.get('groupname').errors?.required">Group name required</mat-error>
				  </mat-form-field>
				</form>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Description</mat-label>
			    <input matInput placeholder="Description" maxlength="200" [class.errorcheck]="this.aMS.description==null" [(ngModel)]="this.aMS.description">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <p *ngIf="editSelected()" class="">Members of Group:</p>

			  <div *ngFor="let target of this.aMS.targets; let itemindex=index">

			    <hr *ngIf="itemindex>0">

					<div class="col-8 pl-0" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">

						  <mat-form-field>
  							<mat-label>Contact</mat-label>
						    <mat-select placeholder="Contact" [(ngModel)]="this.aMS.targets[itemindex]['idcontact']">
			      			<ng-container *ngFor="let contact of this.dDDS.dropdown.contactList">
			      				<mat-option *ngIf="this.aMS.checkContact(itemindex,contact.idcontact)" #prod [value]="contact.idcontact">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</mat-option>
			      			</ng-container>
						    </mat-select>
						  </mat-form-field>

						</div>
					</div>
						 
					<div class="col-4" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">

						  <div class="row">
							  <button mat-icon-button class="col" (click)="this.aMS.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
							  <button mat-icon-button class="col" *ngIf="this.aMS.targets.length>1" (click)="this.aMS.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
							</div>

						</div>
					</div>

				</div>

			  <hr *ngIf="editSelected()">
			  
			</div>

			<button *ngIf="!this.aMS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>

			<button *ngIf="this.aMS.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.aMS.saveChanges()">Update</button>

			<button *ngIf="this.aMS.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>