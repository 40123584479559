import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { Group } from '../add-article/add-article.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddMsggroupService{
	d: Date = new Date();
	private postBody: {};
	httpRes: any;
	public loadedEntry: any;
	public editmode: boolean = false;
	public groupname: string = null;
	public description: string;
	public targets: Group[] = [{"idcontact": ""}];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dDDS: DropDownDataService) {}
	
	addItem() {
		this.targets.push({
			"idcontact": ""
		 });
	}

	removeItem(index: number) {
		this.targets.splice(index, 1);
	}

	addMsgGroup() {
		this.postBody = {
			"name": "addMsgGroup",
			"param":{
				"season": this.loginService.season,
				"groupname": this.groupname,
				"description": this.description,
				"targets": this.targets
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
        		this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
        		this.dDDS.getDropdown("msggroupList");
	        	this.resetForm(false);
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Message Group was not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	saveChanges() {
		this.postBody = {
			"name": "updateMsgGroup",
			"param":{
				"season": this.loginService.season,
				"groupname": this.groupname,
				"description": this.description,
				"targets": this.targets
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	if (response.response.status == '200') {
        			this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
        			this.dDDS.getDropdown("msggroupList");
	        		this.editmode=false;
	        		this.resetForm(false);
	        	} else {
	        		this.loginService.toast("Update Unsuccessful","Message Group was not updated!",'warn');
	        	}
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Message Group was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		this.postBody = {
			"name": "deleteMsgGroup",
			"param":{
				"season": this.loginService.season,
				"groupname": this.groupname
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']['message']);
        		this.dDDS.getDropdown("msggroupList");
	        	this.editmode=false;
	        	this.resetForm(false);
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Message Group was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	resetForm(keeptype: boolean = true) {
    var tempid = this.groupname;
		this.description = null;
	  this.targets = [{"idcontact": ""}];
    window.scroll(0,0);

  }

	editArticle(groupname) {
    this.editmode = true;
    this.groupname = groupname;
    this.loadForEdit();
	}

	loadForEdit() {
		this.loadedEntry = this.dDDS.dropdown['msggroupList'];
		for (var i = 0; i < this.loadedEntry.length; i++) {
			if (this.loadedEntry[i]['groupname']==this.groupname)	{
				var tempid = this.groupname;
				this.groupname = tempid;
				this.description = this.loadedEntry[i].description;
			  this.targets = [];
				for (var j = 0; j < this.loadedEntry[i].targets.length; j++) {
					this.targets.push({
						"idcontact": this.loadedEntry[i]['targets'][j]['idcontact']
					 });
				}
				this.targets = (this.targets==null ? [{"idcontact": ""}] : this.targets);
			}
		}
	}

  checkContact(index,type) {
  	var ans = true;
		for (var i = 0; i < this.targets.length; i++) {
			if (this.targets[i]['idcontact']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}


}