import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { Target } from './add-article.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddArticleService{
	d: Date = new Date();
	private postBody: {};
	httpRes: any;
	public loadedEntry: any;
	public editmode: boolean = false;
	public articleref: string;
	public type: string = 'blog';
	public startdate: string;
	public expirydate: string;
	public title: string;
	public author: string;
	public body: string;
	public date: string = this.d.toISOString().substring(0,10);
	public imageurl: string;
	public url: string;
	public canread: boolean;
	public targets: Target[] = [{"target": "all", "targettype": "group"}];
	public notes: string;
	public imgurl: any;
	public aimgurl: any;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService,
		private sanitizer: DomSanitizer) {}
	
	addItem() {
		if (this.targets[0]["email"]!=="all") {
			this.targets.push({
				"target": "",
				"targettype": "contact"
			 });
		}
	}

	removeItem(index: number) {
		this.targets.splice(index, 1);
	}

	addArticle() {
		this.fixDate('date');
		this.fixDate('startdate');
		this.fixDate('expirydate');
		this.postBody = {
			"name": "addArticle",
			"param":{
				"season": this.loginService.season,
				"type": this.type,
				"startdate": (this.startdate==null ? '' : this.startdate),
				"expirydate": (this.expirydate==null ? '' : this.expirydate),
				"title": this.title,
				"author": (this.author==null ? '' : this.author),
				"body": this.body,
				"date": (this.date==null ? '' : this.date),
				"imageurl": (this.imageurl==null ? '' : this.imageurl),
				"url": (this.url==null ? '' : this.url),
				"canread": (this.canread ? "1" : "0"),
				"targets": this.targets,
				"notes": (this.notes==null ? '' : this.notes)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
        		this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm(false);
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Article was not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	saveChanges() {
		this.fixDate('date');
		this.fixDate('startdate');
		this.fixDate('expirydate');
		this.postBody = {
			"name": "updateArticle",
			"param":{
				"season": this.loginService.season,
				"articleref": this.articleref,
				"type": this.type,
				"startdate": (this.startdate==null ? '' : this.startdate),
				"expirydate": (this.expirydate==null ? '' : this.expirydate),
				"title": this.title,
				"author": (this.author==null ? '' : this.author),
				"body": this.body,
				"date": (this.date==null ? '' : this.date),
				"imageurl": (this.imageurl==null ? '' : this.imageurl),
				"url": (this.url==null ? '' : this.url),
				"canread": (this.canread ? "1" : "0"),
				"targets": this.targets,
				"notes": (this.notes==null ? '' : this.notes)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	if (response.response.status == '200') {
        			this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
	        		this.editmode=false;
	        		this.resetForm(false);
	        		this.articleList();
	        	} else {
	        		this.loginService.toast("Update Unsuccessful","Article was not updated!",'warn');
	        	}
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Article was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		this.postBody = {
			"name": "deleteArticle",
			"param":{
				"season": this.loginService.season,
				"articleref": this.articleref
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm(false);
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Article was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	resetForm(keeptype: boolean = true) {
    var tempid = this.articleref;
		this.type = (keeptype ? this.type : 'blog');
		this.startdate = null;
		this.expirydate = null;
		this.title = null;
	  this.author = null;
	  this.body = null;
	  this.date = this.d.toISOString().substring(0,10);
	  this.imageurl = null;
	  this.url = null;
	  this.canread = true;
	  this.imgurl = null;
	  this.targets = [{"target": "all", "targettype": "contact"}];
	  this.notes = null;	
    window.scroll(0,0);

  }

	editArticle(articleref) {
    this.editmode = true;
    this.articleref = articleref;
    this.loadForEdit();
	}

	loadForEdit() {
		for (var i = 0; i < this.loadedEntry.length; i++) {
			if (this.loadedEntry[i]['articleref']==this.articleref)	{
				var tempid = this.articleref;
				this.articleref = tempid;
				this.type = this.loadedEntry[i].type;
				this.startdate = this.loadedEntry[i].startdate;
				this.expirydate = this.loadedEntry[i].expirydate;
				this.title = this.loadedEntry[i].title;
			  this.author = this.loadedEntry[i].author;
			  this.body = this.loadedEntry[i].body;
			  this.date = this.loadedEntry[i].date;
			  this.imageurl = this.loadedEntry[i].imageurl;
			  this.url = this.loadedEntry[i].url;
			  this.canread = (this.loadedEntry[i].canread=="1" ? true : false);
			  this.targets = [];
				for (var j = 0; j < this.loadedEntry[i].targets.length; j++) {
					this.targets.push({
						"target": (this.loadedEntry[i]['targets'][j]['target']==null ? "all" : this.loadedEntry[i]['targets'][j]['target']),
						"targettype": (this.loadedEntry[i]['targets'][j]['targettype']==null ? "group" : this.loadedEntry[i]['targets'][j]['targettype'])
					 });
				}
				this.targets = (this.targets==null ? [{"target": "all", "targettype": "group"}] : this.targets);
				this.notes = this.loadedEntry[i].notes;	
			  this.sanitizeURL();
			}
		}
	}

	async getDropdown(dataGroup: string, location: number = 1) {
		this.postBody = {
			"name": dataGroup,
			"param": {
				"location": location,
				"season": +this.loginService.season,
				"type": this.type
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	articleList() {
		this.getDropdown('articleList')
		  .then(response => {
		  	this.loginService.setSession(response);
				this.loadedEntry = response['response']['result']['reply'];
	  		this.dropDownDataService.dropdown['articleList'] = this.loadedEntry;
		  })
		  .catch(error => console.log(error));
	}

	sanitizeURL() {
		this.imgurl = this.sanitizer.bypassSecurityTrustStyle("url(" + this.imageurl + ")");
		this.aimgurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageurl);
	}

	typeChange() {
		this.resetForm(true);
		if (this.editmode) {
			this.articleList();
		}
	}

	fixDate(field) {
		if (this[field]!==null && this[field]!=='') {
	  	this[field] = moment(this[field]).format('YYYY-MM-DD');
	  }
	}

}