import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Plugins
import { MDBBootstrapModule, DropdownModule as DropdownMod } from 'angular-bootstrap-md';
import { ButtonsModule, WavesModule, CardsModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { FileUploadModule } from 'primeng/fileupload';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabViewModule } from 'primeng/tabview';
import { KnobModule } from 'primeng/knob';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

// Scanner
import { AppInfoDialogComponent } from './services/scanner/app-info-dialog/app-info-dialog.component';
import { AppInfoComponent } from './services/scanner/app-info/app-info.component';
import { AppUiModule } from './services/scanner/app-ui.module';
import { ScannerComponent } from './services/scanner/app.component';
import { FormatsDialogComponent } from './services/scanner/formats-dialog/formats-dialog.component';

// Base
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PortalService } from './auth/portal.service';
import { CsvService } from './services/csv.service';
import { SearchService } from './search.service';
import { CountryService } from './services/countries.service';
import { SplitTermsService } from './services/splitterms.service';
import { BaseQRService } from './services/qr.service';
import { FilesService } from './services/files.service';

// Pipes
import { AuthGuard } from './auth-guard.service';
import { AlphaPipe } from './alpha.pipe';
import { DeliveryPipe } from './delivery.pipe';
import { PercentmodPipe } from './percentmod.pipe';
import { DashblankPipe } from './dashblank.pipe';
import { YesNoPipe } from './yesno.pipe';
import { CurrencyPipe } from './currencypair.pipe';
import { InstalmentPipe } from './instalment.pipe';
import { DatemodPipe } from './datemod.pipe';
import { OrdinalPipe } from './ordinal.pipe';
import { SearchFilterPipe } from './searchfilter.pipe';
import { DonutChartPipe } from './svgdonut.pipe';


// Client Components
import { ClientAppComponent } from './client/clientapp.component';
import { LoginComponent } from './client/auth/login/login.component';
import { ResetComponent } from './client/auth/reset/reset.component';
import { UnavailableComponent } from './client/auth/unavailable/unavailable.component';
import { HeaderComponent } from './client/header/header.component';
import { HomeComponent } from './client/home/home.component';
import { FooterComponent } from './client/footer/footer.component';
import { OverviewComponent } from './client/overview/overview.component';
import { AnnouncementComponent } from './client/announcement/announcement.component';
import { ArticleComponent } from './client/article/article.component';
import { DeliveryComponent } from './client/delivery/delivery.component';
import { CalculatorComponent } from './client/calculator/calculator.component';
import { RemittanceDetailComponent } from './client/remittancedetail/remittancedetail.component';
import { FarmerRemittanceComponent } from './client/remittance/remittance.component';
import { ClientRemittanceStdComponent } from './client/remittance/standard/remittance.component';
import { ClientRemittanceSizedComponent } from './client/remittance/sized/remittance.component';
import { FarmerInvoiceSelectorComponent } from './client/invoiceselector/invoiceselector.component';
import { FarmerInvoiceComponent } from './client/invoice/invoice.component';
import { ScheduleComponent } from './client/schedule/schedule.component';
import { MapComponent } from './client/map/map.component';
import { FarmComponent } from './client/farm/farm.component';
import { ClientTableComponent } from './client/table/table.component';


// Logistics Components
import { LogisticsAppComponent } from './logistics/logisticsapp.component';
import { LogisticsLoginComponent } from './logistics/auth/login/login.component';
import { LogisticsResetComponent } from './logistics/auth/reset/reset.component';
import { LogisticsUnavailableComponent } from './logistics/auth/unavailable/unavailable.component';
import { LogisticsHomeComponent } from './logistics/home/home.component';
import { LogisticsHeaderComponent } from './logistics/header/header.component';
import { LogisticsTableComponent } from './logistics/table/table.component';
//import { LogisticsAddContactComponent } from './logistics/logging/add-contact/add-contact.component';
//import { LogisticsAddCompanyComponent } from './logistics/logging/add-company/add-company.component';
//import { LogisticsAddOrderComponent } from './logistics/logging/add-order/add-order.component';
import { LogisticsAddQrComponent } from './logistics/qr/add/add-qr.component';
import { LogisticsDispatchQrComponent } from './logistics/qr/dispatch/dispatch-qr.component';


// Buying Components
import { BuyingAppComponent } from './buying/buyingapp.component';
import { BuyingLoginComponent } from './buying/auth/login/login.component';
import { BuyingResetComponent } from './buying/auth/reset/reset.component';
import { BuyingUnavailableComponent } from './buying/auth/unavailable/unavailable.component';
import { BuyingHomeComponent } from './buying/home/home.component';
import { BuyingHeaderComponent } from './buying/header/header.component';
import { BuyingTableComponent } from './buying/table/table.component';
import { BuyingBidOffersComponent } from './buying/bidoffers/bidoffers.component';


// Team Components
import { TeamAppComponent } from './team/teamapp.component';
import { TeamLoginComponent } from './team/auth/login/teamlogin.component';
import { TeamResetComponent } from './team/auth/reset/reset.component';
import { TeamHomeComponent } from './team/home/teamhome.component';
import { TeamHeaderComponent } from './team/header/teamheader.component';
import { AddContactComponent } from './team/logging/add-contact/add-contact.component';
import { AddCompanyComponent } from './team/logging/add-company/add-company.component';
import { AddDeliveryComponent } from './team/logging/add-delivery/add-delivery.component';
import { AddSampleBatchedComponent } from './team/logging/add-sample-batched/add-sample.component';
import { AddPalletSampleComponent } from './team/logging/add-palletsample/add-palletsample.component';
import { AddPaymentComponent } from './team/logging/add-payment/add-payment.component';
import { AddProcessingComponent } from './team/logging/add-processing/add-processing.component';
import { AddCurrencyComponent } from './team/logging/add-currency/add-currency.component';
import { AddOrderComponent } from './team/logging/add-order/add-order.component';
import { AddBookingComponent } from './team/logging/add-booking/add-booking.component';
import { AddArticleComponent } from './team/messages/add-article/add-article.component';
import { AddMsggroupComponent } from './team/messages/add-msggroup/add-msggroup.component';
import { RemittanceComponent } from './team/overview/remittance/remittance.component';
import { RemittanceStdComponent } from './team/overview/remittance/standard/remittance.component';
import { RemittanceSizedComponent } from './team/overview/remittance/sized/remittance.component';
import { CommercialInvoiceComponent } from './team/docs/commercialinvoice/commercialinvoice.component';
import { FumigationComponent } from './team/docs/fumigation/fumigation.component';
import { QualityCertComponent } from './team/docs/qualitycert/qualitycert.component';
import { PackingListComponent } from './team/docs/packinglist/packinglist.component';
import { PPECBComponent } from './team/docs/ppecb/ppecb.component';
import { ShippingInstructionComponent } from './team/docs/shippinginstruction/shippinginstruction.component';
import { VATInvoiceComponent } from './team/docs/vatinvoice/vatinvoice.component';
import { TeamDeliveryComponent } from './team/overview/delivery/teamdelivery.component';
import { TeamCompanyComponent } from './team/overview/company/teamcompany.component';
import { TeamContactComponent } from './team/overview/contact/teamcontact.component';
import { TeamRemittanceDetailComponent } from './team/overview/remittancedetail/teamremittancedetail.component';
import { TeamCalculatorComponent } from './team/calculator/teamcalculator.component';
import { TeamOrderComponent } from './team/overview/order/order.component';
import { BookingComponent } from './team/overview/booking/booking.component';
import { AddQrComponent } from './team/qr/add/add-qr.component';
import { InspectQrComponent } from './team/qr/inspect/inspect-qr.component';
import { DispatchQrComponent } from './team/qr/dispatch/dispatch-qr.component';
import { TraceScanComponent } from './team/traceability/tracescan/tracescan.component';
import { TraceFarmComponent } from './team/traceability/tracefarm/tracefarm.component';
import { TraceInventoryComponent } from './team/traceability/traceinventory/traceinventory.component';
import { TracePackingComponent } from './team/traceability/tracepackinglist/tracepackinglist.component';
import { StockRecallComponent } from './team/traceability/stockrecall/stockrecall.component';
import { RecoverPalletComponent } from './team/overview/recoverpallet/recoverpallet.component';
import { BatchComponent } from './team/traceability/tracebatch/tracebatch.component';
import { TeamPaymentComponent } from './team/overview/payment/payment.component';
import { TeamBatchPaymentComponent } from './team/overview/batchpayment/batchpayment.component';
import { ProcessingComponent } from './team/overview/processing/processing.component';
import { CurrencyComponent } from './team/overview/currency/currency.component';
import { InventoryComponent } from './team/overview/inventory/inventory.component';
import { BatchesComponent } from './team/overview/batches/batches.component';
import { SilosComponent } from './team/overview/silos/silos.component';
import { StockComponent } from './team/overview/stock/stock.component';
import { PickingComponent } from './team/overview/picking/picking.component';
import { PalletActionComponent } from './team/overview/palletaction/palletaction.component';
import { PalletReviewComponent } from './team/overview/palletreview/palletreview.component';
import { SiloSelectorComponent } from './team/silo-selector/silo-selector.component';
import { LogDepotDeliveryComponent } from './team/depot/log-delivery/log-delivery.component';
import { DepotDeliveriesComponent } from './team/depot/depotdeliveries/depotdeliveries.component';
import { DepotDeliveryComponent } from './team/depot/depotdelivery/depotdelivery.component';
import { SettingsClientsNewComponent } from './team/settings/clients/clients-new/clients-new.component';
import { SettingsClientsActiveComponent } from './team/settings/clients/clients-active/clients-active.component';
import { SettingsClientsActivityComponent } from './team/settings/clients/clients-activity/clients-activity.component';
import { SettingsUsersNewComponent } from './team/settings/users/users-new/users-new.component';
import { SettingsUsersActiveComponent } from './team/settings/users/users-active/users-active.component';
import { SettingsUsersActivityComponent } from './team/settings/users/users-activity/users-activity.component';
import { PriceModelComponent } from './team/settings/pricemodel/pricemodel.component';
import { SeasonalSettingsComponent } from './team/settings/seasonal/seasonal.component';
import { GeneralSettingsComponent } from './team/settings/general/general.component';
import { BankAccountsComponent } from './team/settings/bankaccounts/bankaccounts.component';
import { EmailSettingsComponent } from './team/settings/email/email.component';
import { StorageComponent } from './team/settings/storage/storage.component';
import { CultivarComponent } from './team/settings/cultivars/cultivars.component';
import { RemittanceRebuilderComponent } from './team/settings/remittance-rebuilder/remittance-rebuilder.component';
import { QueryComponent } from './team/query/query.component';
import { SchedulingComponent } from './team/scheduling/scheduling.component';
import { TableComponent } from './team/overview/table/table.component';
import { BookingsComponent } from './team/overview/bookings/bookings.component';
// Team Widgets
import { DelSummaryWidget } from './team/widgets/delsummary/delsummary.component';
import { InvSummaryWidget } from './team/widgets/invsummary/invsummary.component';
import { SiloLevelsWidget } from './team/widgets/silolevels/silolevels.component';
import { SiloLevelsService } from './team/widgets/silolevels/silolevels.service';


// Client Services
import { LoginService } from './client/auth/login/login.service';
import { ResetService } from './client/auth/reset/reset.service';
import { HeaderService } from './client/header/header.service';
import { AnnouncementService } from './client/announcement/announcement.service';
import { ArticleService } from './client/article/article.service';
import { ClientDeliveryService } from './client/delivery/delivery.service';
import { FarmerRemittanceService } from './client/remittance/remittance.service';
import { FarmerInvoiceSelectorService } from './client/invoiceselector/invoiceselector.service';
import { CalculatorService } from './client/calculator/calculator.service';
import { ClientTableDataService } from './client/table/table.service';


// Logistics Services
import { LogisticsAuthGuard } from './logistics/logisticsauth-guard.service';
import { LogisticsLoginService } from './logistics/auth/login/login.service';
import { LogisticsResetService } from './logistics/auth/reset/reset.service';
import { LogisticsHeaderService } from './logistics/header/header.service';
import { LogisticsTableDataService } from './logistics/table/table.service';
//import { LogisticsAddContactService } from './logistics/logging/add-contact/add-contact.service';
//import { LogisticsAddCompanyService } from './logistics/logging/add-company/add-company.service';
//import { LogisticsAddOrderService } from './logistics/logging/add-order/add-order.service';
import { LogisticsQRService } from './logistics/qr/qr.service';


// Buying Services
import { BuyingAuthGuard } from './buying/buyingauth-guard.service';
import { BuyingLoginService } from './buying/auth/login/login.service';
import { BuyingResetService } from './buying/auth/reset/reset.service';
import { BuyingHeaderService } from './buying/header/header.service';
import { BuyingTableDataService } from './buying/table/table.service';


// Team Services
import { TeamAuthGuard } from './team/teamauth-guard.service';
import { TeamLoginService } from './team/auth/login/teamlogin.service';
import { TeamAuthService } from './team/auth/auth.service';
import { TeamResetService } from './team/auth/reset/reset.service';
import { TeamHeaderService } from './team/header/teamheader.service';
import { TeamCalculatorService } from './team/calculator/teamcalculator.service';
import { DropDownDataService } from './team/logging/dropdown.service';
import { AddContactService } from './team/logging/add-contact/add-contact.service';
import { AddCompanyService } from './team/logging/add-company/add-company.service';
import { AddDeliveryService } from './team/logging/add-delivery/add-delivery.service';
import { AddSampleBatchedService } from './team/logging/add-sample-batched/add-sample.service';
import { AddPalletSampleService } from './team/logging/add-palletsample/add-palletsample.service';
import { AddPaymentService } from './team/logging/add-payment/add-payment.service';
import { AddProcessingService } from './team/logging/add-processing/add-processing.service';
import { AddCurrencyService } from './team/logging/add-currency/add-currency.service';
import { AddOrderService } from './team/logging/add-order/add-order.service';
import { AddBookingService } from './team/logging/add-booking/add-booking.service';
import { AddArticleService } from './team/messages/add-article/add-article.service';
import { AddMsggroupService } from './team/messages/add-msggroup/add-msggroup.service';
import { DepotDeliveryService } from './team/depot/depotdelivery.service';
import { TeamRemittancesService } from './team/overview/remittances/teamremittances.service';
import { TeamQRService } from './team/qr/qr.service';
import { TraceabilityService } from './team/traceability/traceability.service';
import { TraceInventoryService } from './team/traceability/traceinventory/traceinventory.service';
import { TracePackingService } from './team/traceability/tracepackinglist/tracepackinglist.service';
import { StockRecallService } from './team/traceability/stockrecall/stockrecall.service';
import { RecoverPalletService } from './team/overview/recoverpallet/recoverpallet.service';
import { ProcessingService } from './team/overview/processing/processing.service';
import { CurrencyService } from './team/overview/currency/currency.service';
import { BatchesService } from './team/overview/batches/batches.service';
import { InventoryService } from './team/overview/inventory/inventory.service';
import { RemittanceService } from './team/overview/remittance/remittance.service';
import { SiloService } from './team/overview/silos/silos.service';
import { SiloSelectorService } from './team/silo-selector/silo-selector.service';
import { SettingsClientsService } from './team/settings/clients/clients.service';
import { SettingsUsersService } from './team/settings/users/users.service';
import { PriceModelService } from './team/settings/pricemodel/pricemodel.service';
import { SeasonalSettingsService } from './team/settings/seasonal/seasonal.service';
import { GeneralSettingsService } from './team/settings/general/general.service';
import { BankAccountsService } from './team/settings/bankaccounts/bankaccounts.service';
import { EmailSettingsService } from './team/settings/email/email.service';
import { StorageService } from './team/settings/storage/storage.service';
import { CultivarService } from './team/settings/cultivars/cultivars.service';
import { RemittanceRebuilderService } from './team/settings/remittance-rebuilder/remittance-rebuilder.service';
import { QueryService } from './team/query/query.service';
import { SchedulingService } from './team/scheduling/scheduling.service';
import { TableDataService } from './team/overview/table/table.service';


@NgModule({
    declarations: [
        AppComponent,
        AlphaPipe,
        DeliveryPipe,
        PercentmodPipe,
        DashblankPipe,
        YesNoPipe,
        CurrencyPipe,
        InstalmentPipe,
        DatemodPipe,
        OrdinalPipe,
        SearchFilterPipe,
        DonutChartPipe,

        ClientAppComponent,
        LoginComponent,
        ResetComponent,
        UnavailableComponent,
        HeaderComponent,
        HomeComponent,
        FooterComponent,
        OverviewComponent,
        AnnouncementComponent,
        ArticleComponent,
        DeliveryComponent,
        CalculatorComponent,
        RemittanceDetailComponent,
        FarmerRemittanceComponent,
        ClientRemittanceStdComponent,
				ClientRemittanceSizedComponent,
        FarmerInvoiceSelectorComponent,
        FarmerInvoiceComponent,
        ScheduleComponent,
        MapComponent,
        FarmComponent,
        ClientTableComponent,

        LogisticsAppComponent,
        LogisticsLoginComponent,
        LogisticsResetComponent,
        LogisticsUnavailableComponent,
        LogisticsHomeComponent,
        LogisticsHeaderComponent,
        LogisticsTableComponent,
        //LogisticsAddContactComponent,
        //LogisticsAddCompanyComponent,
        //LogisticsAddOrderComponent,
        LogisticsAddQrComponent,
        LogisticsDispatchQrComponent,

        BuyingAppComponent,
				BuyingLoginComponent,
				BuyingResetComponent,
				BuyingUnavailableComponent,
				BuyingHomeComponent,
				BuyingHeaderComponent,
				BuyingTableComponent,
				BuyingBidOffersComponent,

        TeamAppComponent,
        TeamLoginComponent,
        TeamResetComponent,
        TeamHomeComponent,
        TeamHeaderComponent,
        AddContactComponent,
        AddCompanyComponent,
        AddDeliveryComponent,
        AddSampleBatchedComponent,
        AddPalletSampleComponent,
        AddPaymentComponent,
        AddBookingComponent,
        AddArticleComponent,
        AddMsggroupComponent,
        RemittanceComponent,
        RemittanceStdComponent,
        RemittanceSizedComponent,
        CommercialInvoiceComponent,
        FumigationComponent,
        QualityCertComponent,
        PackingListComponent,
        PPECBComponent,
        ShippingInstructionComponent,
        VATInvoiceComponent,
        TeamCompanyComponent,
        TeamContactComponent,
        TeamRemittanceDetailComponent,
        TeamDeliveryComponent,
        TeamCalculatorComponent,
        TeamOrderComponent,
        BookingComponent,
        AddQrComponent,
        InspectQrComponent,
        DispatchQrComponent,
        TraceScanComponent,
        TraceFarmComponent,
        TraceInventoryComponent,
        TracePackingComponent,
        StockRecallComponent,
        RecoverPalletComponent,
        TeamPaymentComponent,
        TeamBatchPaymentComponent,
        AddProcessingComponent,
        AddCurrencyComponent,
        ProcessingComponent,
        CurrencyComponent,
        InventoryComponent,
        BatchComponent,
        BatchesComponent,
        AddOrderComponent,
        SilosComponent,
        StockComponent,
        PickingComponent,
        PalletActionComponent,
        PalletReviewComponent,
        SiloSelectorComponent,
        LogDepotDeliveryComponent,
        DepotDeliveriesComponent,
        DepotDeliveryComponent,
        SettingsClientsNewComponent,
        SettingsClientsActiveComponent,
        SettingsClientsActivityComponent,
        SettingsUsersNewComponent,
        SettingsUsersActiveComponent,
        SettingsUsersActivityComponent,
        PriceModelComponent,
        SeasonalSettingsComponent,
        GeneralSettingsComponent,
        BankAccountsComponent,
        EmailSettingsComponent,
        StorageComponent,
        CultivarComponent,
        RemittanceRebuilderComponent,
        QueryComponent,
        SchedulingComponent,
        AppInfoDialogComponent,
        AppInfoComponent,
        ScannerComponent,
        FormatsDialogComponent,
        TableComponent,
        BookingsComponent,
        DelSummaryWidget,
        InvSummaryWidget,
        SiloLevelsWidget,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        MDBBootstrapModule,
        ButtonsModule,
        WavesModule,
        CardsModule,
        DropdownMod.forRoot(),
        BrowserAnimationsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MomentDateModule,
        ZXingScannerModule,
        TableModule,
        ButtonModule,
        InputTextModule,
        DropdownModule,
        ToastModule,
        ConfirmDialogModule,
        AppUiModule,
        FullCalendarModule,
        MenubarModule,
        FileUploadModule,
        NgxMatTimepickerModule,
        DynamicDialogModule,
        TabViewModule,
        KnobModule,
    ],
    providers: [
        SearchService,
        PortalService,
        CsvService,
        BaseQRService,
        FilesService,

        LoginService,
        TeamAuthService,
        ResetService,
        AuthGuard,
        HeaderService,
        AnnouncementService,
        ArticleService,
        ClientDeliveryService,
        FarmerRemittanceService,
        FarmerInvoiceSelectorService,
        CalculatorService,
        ClientTableDataService,

        LogisticsAuthGuard,
        LogisticsLoginService,
        LogisticsResetService,
        LogisticsHeaderService,
        LogisticsTableDataService,
        //LogisticsAddContactService,
        //LogisticsAddCompanyService,
        //LogisticsAddOrderService,
        LogisticsQRService,

        BuyingAuthGuard,
				BuyingLoginService,
				BuyingResetService,
				BuyingHeaderService,
				BuyingTableDataService,

        TeamAuthGuard,
        TeamLoginService,
        TeamResetService,
        TeamHeaderService,
        TeamCalculatorService,
        DropDownDataService,
        SplitTermsService,
        CountryService,
        RemittanceService,
        AddContactService,
        AddCompanyService,
        AddDeliveryService,
        AddSampleBatchedService,
        AddPalletSampleService,
        AddPaymentService,
        AddProcessingService,
        AddCurrencyService,
        AddBookingService,
        AddArticleService,
        AddMsggroupService,
        ProcessingService,
        CurrencyService,
        BatchesService,
        InventoryService,
        TeamRemittancesService,
        TeamQRService,
        TraceabilityService,
        TraceInventoryService,
        TracePackingService,
        StockRecallService,
        RecoverPalletService,
        AddOrderService,
        SiloService,
        SiloSelectorService,
        DepotDeliveryService,
        SettingsClientsService,
        SettingsUsersService,
        PriceModelService,
        SeasonalSettingsService,
        GeneralSettingsService,
        BankAccountsService,
        EmailSettingsService,
        StorageService,
        CultivarService,
        RemittanceRebuilderService,
        QueryService,
        SchedulingService,
        TableDataService,
        MessageService,
        ConfirmationService,
        SiloLevelsService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
