import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule, CardsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { AddMsggroupService } from './add-msggroup.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-add-msggroup',
  templateUrl: './add-msggroup.component.html',
  styleUrls: ['./add-msggroup.component.scss']
})
export class AddMsggroupComponent implements OnInit {
	d: Date = new Date();
  form: FormGroup;

  constructor(
  	private loginService: TeamLoginService,
    public aMS: AddMsggroupService,
    public dDDS: DropDownDataService,
    private fb: FormBuilder
  	) { }

  ngOnInit() {
    this.form = this.fb.group({
      groupname: ['', [Validators.required, this.forbiddenStringValidator.bind(this)]]
    });
    window.scrollTo(0,0);
    this.aMS.resetForm();
    this.dDDS.getDropdown("msggroupList");
    this.dDDS.getDropdown("contactList");
  }

  ngOnDestroy() {
    this.aMS.editmode=false;
    this.aMS.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  forbiddenStringValidator(control) {
    if (control.value==undefined || control.value==null) {
      return null;
    }
    const forbiddenStrings = this.dDDS.dropdown.msggroupList;
    if (control.value.trim()==this.aMS.groupname) {
      return null;
    }   
    const forbidden = forbiddenStrings.some(obj => (obj.groupname==null ? null : obj.groupname.trim().toLowerCase()) === (control.value==null ? null : control.value.trim().toLowerCase() ));    
    return forbidden ? { 'forbiddenString': true } : null;
  }
 
  canSave() {
    if (
        this.aMS.groupname !== null &&
        this.aMS.description !== null &&
        this.aMS.targets[0]['email'] !== ""
      ) {
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    if (
        this.aMS.groupname !== null &&
        this.aMS.description !== null &&
        this.aMS.targets[0]['email'] !== ""
      ) {
      return true;
    } else {
      return false;
    }
  }

  onSave() {
    if (this.canSave()) {
      this.aMS.addMsgGroup();
      window.scroll(0,0);
      this.aMS.resetForm();
    }
  }

  editModeOn(state) {
    this.aMS.groupname = '';
    this.aMS.resetForm();
    this.aMS.editmode = state;
  }

  editSelected() {
    return (this.aMS.editmode == false || (this.aMS.editmode == true && this.aMS.groupname.length>0));
  }

  loadForEdit() {
    this.aMS.resetForm();
    this.aMS.loadForEdit();
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this message group?");
    if (res) {
      this.aMS.deleteEntry();    
      this.aMS.resetForm();
    }
  }


}