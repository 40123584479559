import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, WavesModule, CardsModule } from 'angular-bootstrap-md';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { AnnouncementService } from './announcement.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {

  constructor(
  	private loginService: LoginService,
  	public announcementService: AnnouncementService,
    public portalService: PortalService
  	) { }

  ngOnInit() {
  	if(this.loginService.isAuthenticated()) {
      this.announcementService.getAnnouncement();
    }
    window.scrollTo(0,0);
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

}
